import React from "react";
import hero_img from "../../images/covertop.png";
import logo from "../../images/logos/logowhite.png";
import { Navbar } from "flowbite-react";
import { Link } from "react-scroll";

export default function Hero() {
  const nav_items = [
    {
      id: "home",
      name: "Acceuil",
    },
    {
      id: "about",
      name: "About",
    },
    {
      id: "services",
      name: "Services",
    },
    {
      id: "clients",
      name: "Clients",
    },
    {
      id: "contact",
      name: "Contact",
    },
  ];
  return (
    <>
      <section id="home" className="h-screen relative">
        <h1 className="absolute 2xl:top-96 md:w-auto md:top-72 font-gothic left-1/2 -translate-x-1/2 2xl:text-9xl md:text-8xl sm:text-7xl text-5xl w-max top-1/3 uppercase text-white font-bold">
          a different vision
        </h1>
        <div className="absolute w-full">
          <Navbar
            fluid={true}
            style={{
              backgroundColor: "transparent",
            }}
            rounded={true}
          >
            <Navbar.Brand href="/">
              <img src={logo} className="w-32 mt-4" alt="Lahrech Films Prod Logo" />
            </Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse>
              {nav_items.map((item, index) => {
                return (
                  <li
                    key={index}
                    className="font-poppins md:bg-transparent hover:md:bg-transparent text-lg md:text-white hover:cursor-pointer md:hover:border-b-2 md:border-white md:border-b-0 py-4 md:px-2 px-4 border-b-2 border-black bg-white text-black hover:bg-black hover:text-white mx-4 transition-all duration-75"
                  >
                    <Link
                      to={item.id}
                      spy={true}
                      smooth={true}
                      offset={-20}
                      duration={500}
                    >
                      {item.name}
                    </Link>
                  </li>
                );
              })}
            </Navbar.Collapse>
          </Navbar>
        </div>
        <img className="w-full h-full object-cover" src={hero_img} alt="Sahara Cover"/>
      </section>
    </>
  );
}
