import React from "react";
import dz from "./../../images/logos/dz.png";
import besmart from "./../../images/logos/besmart.png";
import cutpp from "./../../images/logos/cutpp.png";
import entp from "./../../images/logos/entp.png";
import kafil from "./../../images/logos/kafil.png";
import innocom from "./../../images/logos/innocom.png";
import safex from "./../../images/logos/safex.png";
import nations from "./../../images/logos/nations.png";
import maghreb from "./../../images/logos/maghreb.png";
import mpeer from "./../../images/logos/mpeer.png";
import switchmed from "./../../images/logos/switchmed.png";
import unicity from "./../../images/logos/unicity.png";
import supreme from "./../../images/logos/supreme.png";
import mcw from "./../../images/logos/mcw.png";
import chrea from "./../../images/logos/chrea.png";
import sosemie from "./../../images/logos/sosemie.png";

export default function Partners() {
  const logos = [
    {
      path: dz,
      alt: "eldjazair_logo",
    },
    {
      path: mpeer,
      alt: "mpeer_logo",
    },
    {
      path: nations,
      alt: "nations_logo",
    },
    {
      path: supreme,
      alt: "supreme_logo",
    },
    {
      path: innocom,
      alt: "innocom_logo",
    },
    {
      path: besmart,
      alt: "besmart_logo",
    },
    {
      path: switchmed,
      alt: "switchmed_logo",
    },
    {
      path: kafil,
      alt: "kafil_logo",
    },
    {
      path: cutpp,
      alt: "cutpp_logo",
    },
    {
      path: unicity,
      alt: "unicity_logo",
    },
    {
      path: maghreb,
      alt: "maghreb_logo",
    },
    {
      path: mcw,
      alt: "mcw_logo",
    },
    {
      path: chrea,
      alt: "chrea_logo",
    },
    {
      path: safex,
      alt: "safex_logo",
    },
    {
      path: entp,
      alt: "entp_logo",
    },
    {
      path: sosemie,
      alt: "sosemie_logo",
    },
  ];
  return (
    <section id="clients" className="my-32">
      <h1 className="font-gothic md:text-6xl text-4xl uppercase font-bold text-center mb-16">
        qui nous fait confiance
      </h1>
      <div className="mx-16 grid md:grid-cols-4 sm:grid-cols-2 gap-8">
        {logos.map((item, index) => {
          return (
            <div key={index} className="w-40 h-40 mx-auto">
              <img
                className=" w-full h-full object-contain grayscale"
                srcSet={item.path}
                alt={item.alt}
              />
            </div>
          );
        })}
      </div>
    </section>
  );
}
