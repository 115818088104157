import React from "react";
import about_img from "../../images/photo cover monochrome.png";

export default function About() {
  return (
    <section id="about" className="h-screen relative z-20">
      <div className="md:w-1/2 2xl:top-64 md:px-0 w-full px-8 absolute md:top-32 top-28 left-1/2 -translate-x-1/2">
        <h1 className="font-gothic md:text-8xl text-6xl uppercase text-white font-bold">
          About Us.
        </h1>
        <p className="mt-6 font-poppins md:text-lg text-sm text-white font-normal text-justify">
          <b>Lahrech Film production</b> est une société
          spécialisée en production cinématographique et d'édition de produits
          audiovisuels implantée en Algérie depuis 2015, LFP, prends en charge
          toute sorte de réalisation visuelle, centrée sur les besoins de nos
          clients, comme nous agissons au service de la communication des
          entreprises sur le plan technique et artistique en fonction d’un
          budget bien déterminé et enfin pour garantir et assurer une meilleure
          qualité audiovisuelle taillée sur-mesure qui a pour objectif de
          satisfaire les besions de nos clients.
        </p>
      </div>
      <img className="w-full h-full object-cover" src={about_img} alt="lahrech films prod team background" />
    </section>
  );
}
