import React from "react";
import logo from "../../images/logos/logowhite.png";
import { FaFacebook, FaInstagram, FaLinkedin, FaYoutube } from "react-icons/fa";

export default function Contact() {
  var d = new Date();
  return (
    <>
      <section
        id="contact"
        className="bg-black text-white grid md:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-4 font-gothic"
      >
        <div className="py-16">
          <img
            className="w-40 h-40 mx-auto"
            src={logo}
            alt="lahrech_films_productions_logo"
          />
        </div>
        <div className="py-16 md:px-8 px-16">
          <h2 className="uppercase text-left text-3xl font-semibold mb-8">
            contactez nous
          </h2>
          <ul className="capitalize text-md md:text-left text-center font-poppins">
            <li>lahrechfilmproduction@gmail.com</li>
          </ul>
        </div>
        <div className="py-16 md:px-8 px-16">
          <h2 className="uppercase text-left text-3xl font-semibold mb-8">
            nos services
          </h2>
          <ul className="capitalize text-md font-poppins">
            <li>films d'entreprise</li>
            <li>vidéo institutionnel</li>
            <li>film corporate</li>
            <li>film promotionnelle</li>
            <li>shooting produits</li>
            <li>emission tv</li>
            <li>couverture événementiel</li>
            <li>spot publicitaire</li>
            <li>visite virtuelle "Matterport"</li>
          </ul>
        </div>
        <div className="py-16 px-8">
          <h2 className="uppercase text-left text-3xl font-semibold mb-8">
            social media
          </h2>
          <ul className="capitalize text-md justify-left font-poppins flex">
            <li className="text-3xl mr-3">
              <a
                href="https://www.facebook.com/LFPprod/"
                className="hover:text-[#4267B2]"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebook />
              </a>
            </li>
            <li className="text-3xl mr-3">
              <a
                href="https://www.instagram.com/lfpalgerie/"
                className="hover:text-[#F56040]"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInstagram />
              </a>
            </li>
            <li className="text-3xl mr-3">
              <a
                href="https://www.youtube.com/channel/UCztB0r0vsVzWHay0ZBELLbA"
                className="hover:text-[#f00]"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaYoutube />
              </a>
            </li>
            <li className="text-3xl mr-3">
              <a
                href="https://www.linkedin.com/company/lahrech-films-production/"
                className="hover:text-[#0072b1]"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedin />
              </a>
            </li>
          </ul>
        </div>
      </section>
      <footer className="w-full bg-white py-1 font-light text-center">
        <small>
          &copy; Copyright {d.getFullYear()}, Lahrech Films Productions
        </small>
      </footer>
    </>
  );
}
