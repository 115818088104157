import React from "react";

import product_one from "../../images/photo1.png";
import product_two from "../../images/photo2.png";
import product_three from "../../images/photo3.png";
import product_four from "../../images/photo4.png";
import product_five from "../../images/photo5.png";

export default function Products() {
  const products_imgs = [
    {
      name: "films d'entreprise",
      path: product_one,
    },
    {
      name: "film promotionnelle",
      path: product_two,
    },
    {
      name: "couverture événementiel",
      path: product_three,
    },
    {
      name: "spot publicitaire",
      path: product_four,
    },
    {
      name: "visite virtuelle Matterport",
      path: product_five,
    },
  ];
  return (
    <section id="services" className="md:h-screen relative">
      <div className="grid md:grid-cols-5 sm:grid-cols-1 md:h-full">
        {products_imgs.map((item, index) => {
          return (
            <div key={index} className="w-full md:h-full h-96 relative hover:scale-95 transition-all duration-150 cursor-pointer">
              <h2
                className="absolute md:w-max w-3/4 left-full bottom-0 capitalize -rotate-90 text-white font-poppins font-bold md:text-4xl text-3xl z-10 md:p-6 p-4"
                style={{
                  transformOrigin: "left bottom",
                }}
              >
                {item.name}
              </h2>
              <img
                className="w-full h-full object-cover"
                src={item.path}
                alt={item.name}
              />
            </div>
          );
        })}
        {/* <div className="w-1/4 h-full relative overflow-hidden">
          <h2
            className="absolute left-0 top-1/2 -rotate-90 text-white font-gothic text-5xl z-10"
            style={{
              transformOrigin: `${100}px ${100}px`,
            }}
          >
            film promotionnelle
          </h2>
          <img
            className="w-full h-full object-cover hover:scale-105 transition-all duration-150 cursor-pointer"
            src={product_two}
          />
        </div>
        <div className="w-1/4 h-full relative overflow-hidden">
          <h2
            className="absolute left-0 top-1/2 -rotate-90 text-white font-gothic text-5xl z-10"
            style={{
              transformOrigin: `${100}px ${100}px`,
            }}
          >
            couverture événementiel
          </h2>
          <img
            className="w-full h-full object-cover hover:scale-105 transition-all duration-150 cursor-pointer"
            src={product_three}
          />
        </div>
        <div className="w-1/4 h-full relative overflow-hidden">
          <h2 className="absolute left-0 top-1/2 -rotate-90 text-white font-gothic text-5xl z-10">
            spot publicitaire
          </h2>
          <img
            className="w-full h-full object-cover hover:scale-105 transition-all duration-150 cursor-pointer"
            src={product_four}
          />
        </div>
        <div className="w-1/4 h-full relative overflow-hidden">
          <h2
            className="absolute left-0 top-1/2 -rotate-90 text-white font-gothic text-5xl z-10"
            style={{
              transformOrigin: `${100}px ${100}px`,
            }}
          >
            visite virtuelle "Matterport"
          </h2>
          <img
            className="w-full h-full object-cover hover:scale-105 transition-all duration-150 cursor-pointer"
            src={product_five}
          />
        </div> */}
      </div>
    </section>
  );
}
