import Hero from "./components/Hero/Hero";
import About from "./components/About/About";
import Products from "./components/Products/Products";
import Partners from "./components/Partners/Partners";
import Contact from "./components/Contact/Contact";

function App() {
  return (
    <>
      <Hero />
      <About />
      <Products />
      <Partners />
      <Contact />
    </>
  );
}

export default App;
